<template>
  <div>
    <select
      v-model='selectValue'
      class='border py-1 px-2 rounded block w-full'>
      <option disabled></option>
      <option v-for='selectableOption in selectableOptions'
        :key='`selectableOption-${selectableOption.id}-${selectableOption.value}`'
        :value='selectableOption.value'>
        {{selectableOption.title}}
      </option>
    </select>
    <input
      v-if='showCustomInput'
      v-model='customInputValue'
      class='border py-1 px-2 rounded block w-full mt-2'
      type='text'
      :placeholder='placeholder'>
  </div>
</template>

<script>
export default {
  name: 'EditFormFieldSelect',
  props: [
    'metaData',
    'value',
  ],
  data () {
    return {
      selectValue: '',
      customInputValue: '',
    }
  },
  watch: {
    value: {
      handler (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          let index = this.selectableOptions.findIndex(selectableOption => selectableOption.value === newVal)
          if (index > -1) {
            this.selectValue = newVal
          } else {
            this.selectValue = 'other'
            this.customInputValue = newVal
          }
        } else if (newVal === '' && oldVal !== '' && oldVal !== null && oldVal !== undefined) {
          this.selectValue = 'other'
        } else {
          this.selectValue = ''
        }
      },
      immediate: true,
    },
    selectValue: {
      handler: function (newVal, oldVal) {
        if (newVal === 'other') {
          this.$emit('update:selectValue', this.customInputValue)
        } else if (newVal !== 'other' && newVal !== oldVal) {
          this.customInputValue = ''
          this.$emit('update:selectValue', newVal)
        }
      }, 
      immediate: true
    },
    customInputValue: {
      handler: function (newVal, oldVal) {
        if (this.showCustomInput && newVal !== oldVal) {
          this.$emit('update:selectValue', newVal)
        }
      }, 
      immediate: true
    },
  },
  computed: {
    selectableOptions () {
      return this.metaData && this.metaData.selectableOptions ? this.metaData.selectableOptions : []
    },
    placeholder () {
      return this.metaData && this.metaData.placeholder ? this.metaData.placeholder : ''
    },
    showCustomInput () {
      return this.selectValue === 'other'
    },
  }
}
</script>
